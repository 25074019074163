import { ArrowSync, Play, Stop } from "@styled-icons/fluentui-system-filled";
import React, { useEffect, useState } from "react";
import DashboardSection from "../../../components/DashboardSection";
import {
  useStatusQuery,
  useToggleStreamMutation,
} from "../../../redux/api/stream/api";
import HLSVideoPlayer from "./HLSVideoPlayer";

interface StreamControllerState {
  isStreaming: boolean;
  statusMessage: string;
  buttonText: string;
}

const Streams: React.FC = () => {
  const streamUrl = "https://stream.maranatha.farm/my_stream/index.m3u8";

  const [streamState, setStreamState] = useState<StreamControllerState>({
    isStreaming: false,
    statusMessage: "Checking stream status...",
    buttonText: "Start Stream",
  });

  // RTK Query hooks
  const {
    data: statusData,
    isLoading: isStatusLoading,
    refetch: refetchStatus,
  } = useStatusQuery(null);

  const [toggleStream, { isLoading: isToggleLoading }] =
    useToggleStreamMutation();

  // Determine if any loading state is active
  const isLoading = isStatusLoading || isToggleLoading;

  useEffect(() => {
    // Update stream state when status data changes
    if (statusData) {
      updateStreamState(statusData.isStreaming);
    }
  }, [statusData]);

  const checkStreamStatus = async (): Promise<void> => {
    try {
      await refetchStatus();
    } catch (error) {
      console.error("Error checking stream status:", error);
      setStreamState({
        isStreaming: false,
        statusMessage: "Error checking stream status",
        buttonText: "Start Stream",
      });
    }
  };

  const handleToggleStream = async (): Promise<void> => {
    try {
      await toggleStream({}).unwrap();

      // Refetch status after toggle
      await refetchStatus();

      // Dispatch event for video player to respond to
      const event = new CustomEvent("stream-status-changed", {
        detail: { isStreaming: !streamState.isStreaming },
      });
      document.dispatchEvent(event);
    } catch (error) {
      console.error("Error toggling stream:", error);
    }
  };

  const updateStreamState = (isStreaming: boolean): void => {
    setStreamState({
      isStreaming,
      statusMessage: isStreaming ? "Stream is online" : "Stream is offline",
      buttonText: isStreaming ? "Stop Stream" : "Start Stream",
    });
  };

  return (
    <div>
      <DashboardSection
        title="Lamb Cam Live Stream Control"
        order={0}
        loading={false}
      >
        <div className="space-y-6">
          <div className="rounded-lg bg-white p-6 shadow-md">
            <div className="mb-4">
              <p className="text-gray-700">
                Welcome to the Lamb Cam stream control panel. Use the buttons
                below to manage the live video feed.
              </p>
            </div>
            <div className="mb-6 flex items-center">
              <div
                className={`mr-2 h-3 w-3 rounded-full ${
                  streamState.isStreaming ? "bg-green-500" : "bg-red-500"
                }`}
              ></div>
              <span className="font-medium text-sm text-gray-700">
                {streamState.statusMessage}
              </span>
            </div>
            <div className="flex flex-wrap gap-4">
              <button
                onClick={handleToggleStream}
                disabled={isLoading}
                className={`font-medium flex items-center gap-2 rounded-md px-4 py-2 transition duration-200 ${
                  streamState.isStreaming
                    ? "bg-red-600 text-white hover:bg-red-700"
                    : "bg-green-600 text-white hover:bg-green-700"
                } ${isLoading ? "cursor-not-allowed opacity-75" : ""}`}
              >
                {streamState.isStreaming ? (
                  <Stop className="h-5 w-5" />
                ) : (
                  <Play className="h-5 w-5" />
                )}
                <span>{streamState.buttonText}</span>
                {isToggleLoading && (
                  <svg
                    className="ml-2 h-4 w-4 animate-spin text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                )}
              </button>
              <button
                onClick={checkStreamStatus}
                disabled={isLoading}
                className={`font-medium flex items-center gap-2 rounded-md bg-gray-200 px-4 py-2 text-gray-700 transition duration-200 hover:bg-gray-300 ${
                  isLoading ? "cursor-not-allowed opacity-75" : ""
                }`}
              >
                <ArrowSync className="h-5 w-5" />
                <span>Refresh Status</span>
                {isStatusLoading && (
                  <svg
                    className="ml-2 h-4 w-4 animate-spin text-gray-700"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                )}
              </button>
            </div>
          </div>

          <div className="h-auto w-full overflow-hidden rounded">
            <HLSVideoPlayer streamUrl={streamUrl} />
          </div>
        </div>
      </DashboardSection>
    </div>
  );
};

export default Streams;
