import { getAuth, signOut } from "firebase/auth";
import { Navigate } from "react-router-dom";
import cartApi from "../../redux/api/cart/api";
import { useAppDispatch } from "../../redux/hooks";

interface Props {}

const Logout = ({}: Props) => {
  const auth = getAuth();
  const dispatch = useAppDispatch();

  // sign the user out
  signOut(auth);

  // reset the cartApi state
  dispatch(cartApi.util.resetApiState());

  return <Navigate to="/login" />;
};

export default Logout;
