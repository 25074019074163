import { SignOut, Video } from "@styled-icons/fluentui-system-regular";
import { NavLink } from "react-router-dom";
import Dashboard, { DashboardElement } from "../../components/Dashboard";
import { AltLogo } from "../../components/Logo";
import { UserRole } from "../../constants/firestore";

const Admin = () => {
  const AdminElements: DashboardElement[] = [
    {
      to: "/streams",
      title: "Streams",
      icon: Video,
      requiredRoles: [UserRole.Admin],
    },
    {
      to: "/logout",
      title: "Logout",
      icon: SignOut,
    },
  ];

  return (
    <div className="flex flex-col items-center justify-start space-y-4 bg-gray-100 pt-8">
      <div className="flex h-20 w-full items-center justify-center">
        <div className="h-full w-56 cursor-pointer border-r border-gray-200 p-4">
          <NavLink to="/">
            <AltLogo style={"color"} />
          </NavLink>
        </div>
        <div className="flex h-full w-fit items-center justify-start p-4 text-2xl font-light text-gray-600">
          Admin
        </div>
      </div>

      <div className="w-full max-w-5xl">
        <Dashboard groups={AdminElements} />
      </div>
    </div>
  );
};

export default Admin;
