import Hls from "hls.js";
import React, { useEffect, useRef, useState } from "react";

interface HLSVideoPlayerProps {
  streamUrl: string;
}

interface StreamStatusEvent extends CustomEvent {
  detail: {
    isStreaming: boolean;
  };
}

const HLSVideoPlayer: React.FC<HLSVideoPlayerProps> = ({ streamUrl }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const hlsRef = useRef<Hls | null>(null);
  const bufferingTimerRef = useRef<NodeJS.Timeout | null>(null);
  const [showMessage, setShowMessage] = useState<boolean>(true);
  const [streamMessage, setStreamMessage] = useState<string>(
    "Checking stream status..."
  );

  useEffect(() => {
    // Event listener for stream status changes
    const handleStreamStatusChange = (event: Event) => {
      const streamEvent = event as StreamStatusEvent;
      if (streamEvent.detail && streamEvent.detail.isStreaming !== undefined) {
        if (streamEvent.detail.isStreaming) {
          startPlayer();
        } else {
          stopPlayer();
          setShowMessage(true);
          setStreamMessage("Stream is offline");
        }
      }
    };

    document.addEventListener(
      "stream-status-changed",
      handleStreamStatusChange
    );

    // Initialize player on component mount
    startPlayer();

    // Clean up on component unmount
    return () => {
      document.removeEventListener(
        "stream-status-changed",
        handleStreamStatusChange
      );
      stopPlayer();
    };
  }, [streamUrl]);

  const startPlayer = () => {
    // Clean up existing player
    stopPlayer();

    const video = videoRef.current;
    if (!video) return;

    if (Hls.isSupported()) {
      try {
        // Enhanced configuration with more aggressive buffering and prefetching
        const hlsConfig = {
          // Increase buffer targets to prefetch more content
          maxBufferLength: 60, // Buffer up to 60 seconds (increased from 30)
          maxMaxBufferLength: 90, // Maximum buffer size during seeking/recovery

          // Increase lookahead distance to prefetch segments earlier
          backBufferLength: 30, // Keep 30 seconds of video in memory for backwards seeking
          liveSyncDurationCount: 7, // Increase from 3 to 7 segments to prefetch ahead
          liveMaxLatencyDurationCount: 10, // Increased from 5 for more buffer room

          // More aggressive loading and fragmentation settings
          maxBufferHole: 0.5, // Maximum buffer holes to jump in seconds
          maxFragLookUpTolerance: 0.25, // Tolerance to search for fragments
          lowLatencyMode: false, // Disable low latency mode for better buffering

          // Retry and timeout settings
          manifestLoadingMaxRetry: 8, // Increased from 5
          manifestLoadingRetryDelay: 1000, // Reduced from 2000 for faster retries
          manifestLoadingMaxRetryTimeout: 64000, // Maximum timeout for manifest retries
          fragLoadingMaxRetry: 15, // Increased from 10
          fragLoadingRetryDelay: 1000, // Reduced from 2000

          // Enable chunk appending in certain browsers for smoother playback
          appendErrorMaxRetry: 5,

          // Tune ABR (adaptive bitrate) algorithm to prefer higher buffer levels
          abrEwmaDefaultEstimate: 5000000, // Default bandwidth estimate (5Mbps)
          abrEwmaFastLive: 3.0, // Faster ABR level switching for live streams
          abrEwmaSlowLive: 9.0, // Slower ABR level down-switching in stable conditions
          startLevel: -1, // Auto select initial level based on bandwidth
        };

        const hls = new Hls(hlsConfig);
        hlsRef.current = hls;

        // Early binding to prevent race conditions
        hls.attachMedia(video);

        hls.on(Hls.Events.MEDIA_ATTACHED, () => {
          console.log("Media attached successfully");
          setShowMessage(true);
          setStreamMessage("Loading stream...");

          try {
            hls.loadSource(streamUrl);

            // Set the initial buffer goal higher
            if (hls.config && hls.config.startFragPrefetch !== undefined) {
              hls.config.startFragPrefetch = true;
            }
          } catch (e) {
            console.error("Error loading source:", e);
            setShowMessage(true);
            setStreamMessage("Error loading stream");
          }
        });

        hls.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
          console.log("Manifest parsed successfully", data);
          setShowMessage(false);

          // Start at a slightly lower quality to build buffer faster
          if (data && data.levels && data.levels.length > 1) {
            // Start with medium quality to build buffer quickly
            const startLevel = Math.floor(data.levels.length / 2) - 1;
            if (startLevel >= 0) {
              hls.currentLevel = startLevel;

              // After buffer is established, switch to automatic level selection
              setTimeout(() => {
                if (hlsRef.current) {
                  hlsRef.current.currentLevel = -1; // Auto level selection
                }
              }, 10000); // After 10 seconds, switch to automatic quality
            }
          }

          try {
            // Higher volume to ensure audio detection systems see the stream as active
            video.volume = 0.1;
            video.play().catch((e) => {
              console.warn("Playback issue:", e);
            });
          } catch (e) {
            console.error("Play error:", e);
          }
        });

        // Monitor and manage fragmentation loading
        hls.on(Hls.Events.FRAG_LOADING, () => {
          // This event fires when a fragment starts loading
          if (bufferingTimerRef.current) {
            clearTimeout(bufferingTimerRef.current);
            bufferingTimerRef.current = null;
          }
        });

        hls.on(Hls.Events.FRAG_LOADED, () => {
          // Successfully loaded a fragment - can use this to monitor buffer health
          if (showMessage) {
            // If we're showing a buffering message but still loading fragments, probably okay
            setTimeout(() => {
              if (video && !video.paused && hlsRef.current) {
                setShowMessage(false);
              }
            }, 500);
          }
        });

        hls.on(Hls.Events.ERROR, (event: string, data: any) => {
          console.error("HLS error:", data.type, data.details);

          if (data.fatal) {
            switch (data.type) {
              case Hls.ErrorTypes.NETWORK_ERROR:
                setShowMessage(true);
                setStreamMessage("Network error. Retrying...");
                setTimeout(() => {
                  try {
                    // Try to continue loading from where we left off
                    hls.startLoad();

                    // If still having issues after a few seconds, recover more aggressively
                    setTimeout(() => {
                      if (showMessage && hlsRef.current === hls) {
                        stopPlayer();
                        setTimeout(() => startPlayer(), 1000);
                      }
                    }, 5000);
                  } catch (e) {
                    console.error("Error on startLoad:", e);
                    // Recreate player on error
                    stopPlayer();
                    setTimeout(() => startPlayer(), 1000);
                  }
                }, 500); // Reduced from 1000ms
                break;

              case Hls.ErrorTypes.MEDIA_ERROR:
                setShowMessage(true);
                setStreamMessage("Media error. Recovering...");
                try {
                  // First try simple recovery
                  hls.recoverMediaError();

                  // If that doesn't work, try a more aggressive approach
                  setTimeout(() => {
                    if (showMessage && hlsRef.current === hls) {
                      // Try swapping the renderer
                      if (video) {
                        video.currentTime = video.currentTime - 5; // Back up 5 seconds
                      }
                      hls.swapAudioCodec();
                      hls.recoverMediaError();
                    }
                  }, 2000);
                } catch (e) {
                  console.error("Error on recoverMediaError:", e);
                  // Recreate player on error
                  stopPlayer();
                  setTimeout(() => startPlayer(), 1000);
                }
                break;

              default:
                setShowMessage(true);
                setStreamMessage("Stream error. Restarting...");
                stopPlayer();
                setTimeout(() => startPlayer(), 2000);
                break;
            }
          } else if (data.details === Hls.ErrorDetails.BUFFER_STALLED_ERROR) {
            // Non-fatal buffer stall - try to recover smoothly
            setShowMessage(true);
            setStreamMessage("Buffering...");

            if (video && video.currentTime > 5) {
              // If possible, back up a bit to get fresh content
              try {
                video.currentTime = video.currentTime - 2;
              } catch (e) {
                console.error("Error setting current time:", e);
              }
            }
          }
        });

        // Enhanced buffering detection and recovery
        const handleWaiting = (): void => {
          // Don't show buffering message immediately - wait a bit to avoid flashing
          bufferingTimerRef.current = setTimeout(() => {
            setShowMessage(true);
            setStreamMessage("Buffering...");

            // If we're still buffering after a longer period, try a more aggressive recovery
            setTimeout(() => {
              if (showMessage && hlsRef.current === hls) {
                // Try to nudge the player to recover
                if (video && video.buffered.length) {
                  const bufferedEnd = video.buffered.end(
                    video.buffered.length - 1
                  );
                  const currentTime = video.currentTime;

                  if (bufferedEnd > currentTime + 0.5) {
                    // If we have buffer ahead but we're stalled, jump ahead slightly
                    try {
                      video.currentTime = currentTime + 0.5;
                    } catch (e) {
                      console.error("Error adjusting currentTime:", e);
                    }
                  }
                }
              }
            }, 5000);
          }, 1000); // Increased from 1500ms for less UI flashing
        };

        const handlePlaying = (): void => {
          if (bufferingTimerRef.current) {
            clearTimeout(bufferingTimerRef.current);
            bufferingTimerRef.current = null;
          }
          setShowMessage(false);
        };

        // Add buffer monitoring to proactively avoid buffering
        const monitorBuffer = () => {
          if (!video || !hls || video.paused) return;

          if (video.buffered.length) {
            const currentTime = video.currentTime;
            const bufferedEnd = video.buffered.end(video.buffered.length - 1);
            const bufferAhead = bufferedEnd - currentTime;

            // If buffer is getting low, show an early warning
            if (bufferAhead < 2 && !showMessage) {
              setShowMessage(true);
              setStreamMessage("Low buffer, preparing next segments...");
            } else if (bufferAhead > 3 && showMessage) {
              setShowMessage(false);
            }

            // If seriously low on buffer, try to reduce quality to build buffer
            if (bufferAhead < 1 && hls.currentLevel > 0) {
              // Temporarily switch to a lower quality to build buffer faster
              const lowerLevel = Math.max(0, hls.currentLevel - 1);
              hls.nextLevel = lowerLevel;

              // After buffer is rebuilt, allow auto quality again
              setTimeout(() => {
                if (hlsRef.current === hls) {
                  hls.nextLevel = -1;
                }
              }, 10000);
            }
          }
        };

        const bufferMonitorInterval = setInterval(monitorBuffer, 2000);

        video.addEventListener("waiting", handleWaiting);
        video.addEventListener("playing", handlePlaying);

        // Return the cleanup for these specific event listeners
        return () => {
          video.removeEventListener("waiting", handleWaiting);
          video.removeEventListener("playing", handlePlaying);
          clearInterval(bufferMonitorInterval);
        };
      } catch (e) {
        console.error("Fatal error initializing HLS player:", e);
        setShowMessage(true);
        setStreamMessage("Cannot initialize player");
      }
    } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
      // Native HLS support (Safari)
      video.src = streamUrl;
      video.addEventListener("loadedmetadata", () => {
        video.play();
      });
    } else {
      setShowMessage(true);
      setStreamMessage("Your browser does not support HLS streaming.");
    }
  };

  const stopPlayer = (): void => {
    try {
      const video = videoRef.current;

      // Clear buffering timer if it exists
      if (bufferingTimerRef.current) {
        clearTimeout(bufferingTimerRef.current);
        bufferingTimerRef.current = null;
      }

      if (video) {
        video.pause();
        video.removeAttribute("src");
        video.load();
      }

      if (hlsRef.current) {
        hlsRef.current.destroy();
        hlsRef.current = null;
      }
    } catch (e) {
      console.error("Error stopping player:", e);
    }
  };

  // Add a useEffect to handle video metadata loading and resizing
  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const handleMetadataLoaded = () => {
      // When video metadata is loaded, we know the video's dimensions
      if (video.videoWidth && video.videoHeight) {
        // Let the container adjust to the video's aspect ratio
        const container = video.parentElement;
        if (container) {
          container.style.aspectRatio = `${video.videoWidth} / ${video.videoHeight}`;
        }
      }
    };

    video.addEventListener("loadedmetadata", handleMetadataLoaded);

    return () => {
      video.removeEventListener("loadedmetadata", handleMetadataLoaded);
    };
  }, []);

  return (
    <div className="relative flex h-auto w-full items-center justify-center overflow-hidden">
      <div className="relative h-auto w-full">
        <video
          ref={videoRef}
          id="video"
          className="h-auto w-full bg-black"
          playsInline
          muted
          controls
        ></video>

        {showMessage && (
          <div className="absolute inset-0 flex h-auto items-center justify-center bg-black bg-opacity-70 text-white">
            <div className="p-4 text-center">
              <p className="text-xl">{streamMessage}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HLSVideoPlayer;
