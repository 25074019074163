import { Helmet } from "react-helmet-async";
import { Navigate, Route, Routes } from "react-router-dom";
import Page404 from "../components/ErrorPages/404";
import Logout from "../components/Logout";
import ProtectedRoute from "../components/ProtectedRoute";
import { UserRole } from "../constants/firestore";
import Admin from "./Admin";
import Streams from "./Admin/Streams";
import Login from "./Login";

const AppBody = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute
            requiredRoles={[
              UserRole.Admin,
              UserRole.AdminReadonlyOfferings,
              UserRole.FulfillmentManager,
            ]}
          >
            <Helmet>
              <title>Admin | Maranatha Farm</title>
              <link rel="canonical" href="http://admin.maranatha.farm" />
            </Helmet>
            <Admin />
          </ProtectedRoute>
        }
      >
        <Route index={true} element={<Navigate to={"streams"} />} />

        <Route
          path="streams"
          element={
            <ProtectedRoute requiredRoles={[UserRole.Admin]}>
              <Streams />
            </ProtectedRoute>
          }
        />
      </Route>

      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />

      {/* 404 Page */}
      <Route
        path="*"
        element={
          <>
            <Helmet>
              <title>404 | Maranatha Farm</title>
            </Helmet>
            <Page404 />
          </>
        }
      />
    </Routes>
  );
};

export default AppBody;
