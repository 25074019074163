import { Navigate } from "react-router-dom";
import { UserRole } from "../../constants/firestore";
import { useAuthentication } from "../../hooks/useAuthentication";

import { useToggleLoginModal } from "../../redux/state/modals/hooks";
import Loader from "../Loader";

interface Props {
  requiredRoles: UserRole[];
  children: any;
}

const ProtectedRoute = ({ requiredRoles, children }: Props) => {
  const openLoginModal = useToggleLoginModal();
  const { currentUser, claims } = useAuthentication();

  if (!currentUser || !claims) {
    return (
      <div className="flex h-auto w-full flex-col items-center justify-center p-40">
        <Loader />
      </div>
    );
  }

  const hasValidClaims = Object.keys(claims).some(
    (claim) =>
      requiredRoles.findIndex((validClaim) => validClaim === claim) !== -1
  );

  if (currentUser.email && hasValidClaims) {
    return children;
  }

  if (currentUser.email === null) {
    openLoginModal();
    return <Navigate to="/logout" />;
  }

  return <Navigate to="/logout" />;
};

export default ProtectedRoute;
