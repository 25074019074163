import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const streamApi = createApi({
  reducerPath: "streamApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `https://stream.maranatha.farm/api/`,
    // append a custom X-Api-Key header to every request
    prepareHeaders: (prepareHeaders) => {
      prepareHeaders.set("X-API-Token", "73a655134ff410c3cd2acce35cbcd0ee");
      return prepareHeaders;
    },
  }),
  endpoints: (builder) => ({
    status: builder.query({
      query: () => ({
        url: `/status`,
        method: "GET",
      }),
    }),

    toggleStream: builder.mutation({
      query: (arg) => ({
        url: `/toggle`,
        method: "POST",
      }),
    }),
  }),
});

export const { useStatusQuery, useToggleStreamMutation } = streamApi;

export default streamApi;
